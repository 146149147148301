<template>
    <div> 
       <main-layout/>
    </div>
</template>

<script>
import MainLayout from '@/components/layout/MainLayout.vue'
export default {
  name: 'App',
  components: {
    MainLayout,
  },
  created(){
  },
  methods:{
  }
}
</script>